<template>
  <div>
    <div class="navMenu-main">
      <div id="menu" class="gn-icon-menu"><span></span></div>
    </div>
    <Header :instanceInfo="instanceInfo" />
    <div class="main">
      <div class="container">
        <div class="row">
          <AdminMenu :instanceInfo="instanceInfo" />
          <div class="col-md-9">
					<h2 class="pink">Chat</h2>
                    <h4>Now you are chatting with <span class="pink">{{ realUserName }}</span><br/>
                    as <span class="pink">{{ profileName }}</span></h4>
					<div class="shadow padded">
						<div class="col2">
              <form accept-charset="UTF-8" class="message" @submit.prevent="submitMessage">
      <div class="txtarea clearfix">
          <div class="leftcol">
            <textarea v-model="messageBody" maxlength="700" placeholder="Write a message..." data-field="message" style="resize: none;"
                      class="noglow" name="message" cols="50" rows="10"></textarea>
          </div>
          <div class="rightcol">
            <ul>

                  <li>
                    <EmojiDropdown />
                  </li>

                  <li>
                    <div class="file-up">
                      <input id="image" name="image" size="59" type="file" accept=".gif,.jpg,.jpeg,.png">
                      <a class="btn-addphoto" href="javascript:void(0)"><i class="camera"></i></a>
                    </div>
                  </li>
                  <li>
                    <input class="btn" type="submit" value="Send">
                  </li>
                  <li>
                    Minutes delay(from last message):
                  </li>
                  <li>
                    <input class="form-control ui-autocomplete-input" type="number" style="max-width: 70px;" v-model="minutesDelay">
                  </li>
              </ul>
          </div>
      </div><!--/txtarea-->
    </form>
    <div v-if="submitFailed" class="alert alert-danger alert-dismissible">
                    <button @click="clearFailureMessage" type="button" class="close" data-dismiss="alert"
                        aria-label="Close"><span aria-hidden="true">×</span></button>
                    <p>Your message couldn't be delivered. Check your credits and try again later or contact our support!</p>
                </div>

  </div><!--/col2-->
						<p>&nbsp;</p>
						<ul class="chat-list">
              <li v-for="message in messages" :key="message.uuid" :class="{ 'reply': message.memberFromDto.name == profileName }">
        <div class="chat-profile-photo rounded centered-backgroud" :style="'background-image: url(\'' + getMemberImage(message) + '\');'"></div>

			<div class="msgcont">
				<h5>
					<span class="msg">
													{{ message.flirtDto ? message.flirtDto.body : message.body }}
                          <a v-if="message.attachmentDto" :href="'/uploads/users/' + message.attachmentDto.name" data-lightbox="photo-collection-9795370">
   <div class="wrapper" style="position: relative; margin-top: 15px; height: 75px; width: 75px;">
      <img :src="'/uploads/users/' + message.attachmentDto.name" style="width: 75px; height: 75px;">
      <img src="/theme/001/images/icon_attachment.png" style="position: absolute; bottom: -5px; right: -5px;">
   </div>
   <small>
   <i>
   Click to open attachment
   </i>
   </small>
</a>
            </span>
					<br>
					<span class="time">
						{{ formatDate(message.createdAt) }}
					</span>
				</h5>
			</div>
              </li>
			</ul>
                    </div><!--/shadow-->
                    <div class="row">
		                        <div class="col-md-12">
                              <div class="pager center">
                <div class="pagination">
                  <ul>
                    <li :class="{ 'disabled': currentPage == 1 }">
                      <router-link v-if="currentPage > 1" :to="'/admin/to-reply/conversations/' + uuid + '?page=' + (currentPage - 1)"
                        rel="prev">&laquo;</router-link>
                      <span v-else>&laquo;</span>
                    </li>
                    <li v-for="n in pagesCount" :key="n" :class="{ 'active': n == currentPage }">
                      <router-link v-if="n != currentPage" :to="'/admin/to-reply/conversations/' + uuid + '?page=' + n">{{ n }}</router-link>
                      <span v-else>{{ n }}</span>
                    </li>
                    <li :class="{ 'disabled': currentPage == pagesCount }">
                      <router-link v-if="currentPage < pagesCount" :to="'/admin/to-reply/conversations/' + uuid + '?page=' + (currentPage + 1)"
                        rel="next">&raquo;</router-link>
                      <span v-else>&raquo;</span>
                    </li>
                  </ul>
                </div>
              </div>
		                        </div>
		                        </div>
				</div>
      </div>
    </div>
  </div>
  <Footer :instance-info="instanceInfo" :company-name="companyName" v-bind="$attrs"/>
</div>
</template>

<script>
import Header from '@/app/components/Header';
import Footer from '@/app/components/Footer';
import AdminMenu from '@/app/components/AdminMenu';
import EmojiDropdown from '@/app/components/EmojiDropdown';
import { useUrl, redirectToRoute } from '@/app/router';
import { fetchConversationMessages, sendReply } from '@/app/api/message';
import { useQuery } from '@/app/use/router';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import moment from 'moment';

export default {
  name: 'ToReplyConversation',
  components: { Header, Footer, AdminMenu, EmojiDropdown },
  props: {
    instanceInfo: {
      type: Object,
      required: false,
    },
    companyName: {
      type: String,
      required: false,
    },
  },
  setup() {
    const { currentPath, uuid } = useUrl();
    const realUserName = ref(null);
    const profileName = ref(null);
    const lastMessageCreatedAt = ref(null);

    const route = useRoute();
    const { pagination } = useQuery();
    const messages = ref([]);
    const totalItems = ref(0);
    const pagesCount = ref(1);
    const currentPage = ref(1);

    const reloadEmojiScript = function () {
        document.getElementById('emoji-script')?.remove();
        const emojiPlugin = document.createElement('script');
        emojiPlugin.src = '/global/emoji/jquery.emoji.js';
        emojiPlugin.id = 'emoji-script';
        emojiPlugin.async = true;
        document.body.appendChild(emojiPlugin);
    };

    const loadMessages = () => {
      pagination.value.rowsPerPage = 5;
      console.log(uuid.value);
      fetchConversationMessages(uuid.value, {
        pagination: pagination.value,
      })
        .then(async data => {
          messages.value = data.items;
          totalItems.value = data.total;
          pagesCount.value = data.pagesCount;
          currentPage.value = data.page;
          realUserName.value = data.items[data.items.length - 1].memberFromDto.name;
          profileName.value = data.items[data.items.length - 1].memberToDto.name;
          lastMessageCreatedAt.value = data.items[0].createdAt;
          reloadEmojiScript();
        });
    };

    loadMessages();

    watch(
      () => route.query.page,
      async () => {
        loadMessages();
      },
      { immediate: true }
    );

    const formatDate = (dateString) => {
      const date = new Date(Date.parse(dateString));
        return moment(date).format("Do MMMM HH:mm");
    };

    const getMemberImage = (message) => {
      if (message.memberFromDto.image == null) {
        return '/global/img/thumb.gif';
      } else if (message.memberToDto.name == realUserName.value) {
        return '/uploads/' + message.memberFromDto.image;
      } else {
        return '/uploads/users/' + message.memberFromDto.image;
      }
    }

    const getSentByInfo = (message) => {
        return '(sent by ' + message.memberFromDto.name + ' )';
    }

    const submitFailed = ref(false);

    const messageBody = ref('');
    const minutesDelay = ref(15);

    const submitMessage = () => {
      const imageInput = document.querySelector('#image');
      const file = imageInput.files[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          const base64File = reader.result;
          const message = {
            body: messageBody.value,
            attachmentDto: {
              name: file.name,
              content: base64File
            },
            createdAt: moment(lastMessageCreatedAt.value).add(minutesDelay.value, 'm').format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
            conversation: uuid.value
          };
          console.log(message);
          submitMessageData(message);
        };
        imageInput.value = null;
      } else {
        const message = {
            body: messageBody.value,
            createdAt: moment(lastMessageCreatedAt.value).add(minutesDelay.value, 'm').format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
            conversation: uuid.value
          };
        submitMessageData(message);
      }
    };

    const submitMessageData = (message) => {
      sendReply(message)
        .then(() => {
          messageBody.value = '';
          if (route.query.page > 1) {
            //to seem just sent message
          redirectToRoute('/admin/to-reply/conversations/' + uuid.value)
          } else {
            loadMessages();
          }
        })
        .catch(() => {
          submitFailed.value = true;
        });
    };

    const clearFailureMessage = () => {
      submitFailed.value = false;
    };

    return {
      currentPath,
      messages,
      totalItems,
      pagesCount,
      currentPage,
      pagination,
      realUserName,
      profileName,
      uuid,
      submitFailed,
      messageBody,
      formatDate,
      getMemberImage,
      getSentByInfo,
      submitMessage,
      clearFailureMessage,
      minutesDelay
    };
  },
};
</script>
